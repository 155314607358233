import React, { useEffect, useState } from "react"
import "../svg-css/color_palette.css"
import { useWindowScroll } from "react-use"
import { scale, linearScaler } from "../../../../../scalers/scale"
import { useSize } from "../../../../../hooks/useSize"
import { document } from "browser-monads"

const ColorPalette = () => {
    const scrollInfo = useWindowScroll();
    const size = useSize();
    const pageHeight = document.documentElement.offsetHeight;

    const [logo_opa, setLogo_opa] = useState(0);
    const [logo_color, setLogo_color] = useState("#383838");
    const [background_color, setBackground_color] = useState("#ffffff");

    useEffect(() => {
        let percentage = scrollInfo.y/(pageHeight-size.height)*100;
        console.log(percentage);
        setLogo_opa(scale([86, 87, 99, 100], [0, 1, 1, 0], percentage, linearScaler));
        setBackground_color(
            'rgb(' +
            scale([88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99], [255, 25, 25, 17, 17, 48, 48, 204, 204, 204, 204, 255], percentage, linearScaler) + ',' +
            scale([88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99], [255, 166, 166, 103, 103, 50, 50, 74, 74, 122, 122, 255], percentage, linearScaler) + ',' + 
            scale([88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99], [255, 221, 221, 206, 206, 51, 51, 17, 17, 23, 23, 255], percentage, linearScaler) + ')');
        setLogo_color(
            'rgb(' +
            scale([90, 91, 96, 97], [56, 234, 234, 56], percentage, linearScaler) + ',' +
            scale([90, 91, 96, 97], [56, 234, 234, 56], percentage, linearScaler) + ',' + 
            scale([90, 91, 96, 97], [56, 234, 234, 56], percentage, linearScaler) + ')');
    }, [scrollInfo, pageHeight, size]);

    return (
        <>
        <div style={{
            backgroundColor: background_color,
            width: "150%",
            height: "150%",
            position: "fixed",
            transform: "translate(-20%, -20%)",
            zIndex: -1
        }}>

        </div>
        <div className="colorPaletteLogo" style={{
            width: "70%",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            opacity: logo_opa,
            fill: logo_color
        }}>
            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398.77 108.29">
            <g>
                <path class="color-c" d="M5.64,31.43c-.3,.08-.55-.23-.42-.5,3.53-7.46,8.77-14.05,15.31-19.23,3.29-2.6,6.85-4.8,10.6-6.57,.11-.05,.24-.04,.34,.02l22.48,13.35c.28,.16,.22,.58-.1,.66l-22.07,5.62L5.64,31.43Z"/>
                <path class="color-c" d="M3.79,72.38c-.16,.26-.55,.23-.65-.06C.36,64.54-.6,56.17,.36,47.89c.48-4.15,1.44-8.22,2.84-12.11,.04-.11,.14-.2,.25-.23l25.34-6.45c.31-.08,.56,.26,.4,.53l-10.73,18.05-.9,1.5L3.79,72.38Z"/>
                <path class="color-c" d="M31.45,102.66c.08,.3-.23,.55-.5,.42-7.48-3.54-14.08-8.79-19.25-15.33-2.59-3.26-4.79-6.81-6.55-10.57-.05-.11-.04-.24,.02-.34l13.36-22.48c.16-.28,.58-.22,.66,.1l12.28,48.21Z"/>
                <path class="color-c" d="M47.89,107.92c-4.14-.48-8.21-1.43-12.1-2.83-.11-.04-.2-.14-.23-.25l-6.45-25.34c-.08-.31,.26-.56,.53-.4l18.07,10.74,1.49,.89,23.18,13.78c.26,.16,.23,.55-.06,.65-7.77,2.77-16.12,3.72-24.4,2.77h-.02Z"/>
                <path class="color-c" d="M54.38,89.79c-.28-.16-.22-.58,.1-.66l20.33-5.17h0l27.83-7.09c.3-.08,.55,.23,.42,.5-3.54,7.45-8.78,14.04-15.32,19.22-3.26,2.58-6.81,4.78-10.58,6.55-.11,.05-.24,.04-.34-.02l-22.45-13.35Z"/>
                <path class="color-c" d="M76.89,5.65c-.08-.3,.23-.55,.5-.42,7.45,3.54,14.02,8.77,19.18,15.29,2.59,3.27,4.79,6.82,6.56,10.57,.05,.11,.05,.24-.02,.34l-13.33,22.46c-.16,.28-.58,.22-.66-.1L76.89,5.65Z"/>
                <path class="still-hor-b" d="M89.67,60.87l1.05-1.77,13.78-23.21c.16-.26,.55-.23,.65,.06,2.78,7.78,3.74,16.15,2.77,24.44-.48,4.16-1.44,8.23-2.84,12.13-.04,.11-.14,.2-.25,.23l-25.32,6.44c-.31,.08-.56-.26-.4-.53l10.56-17.79Z"/>
                <path class="color-c" d="M60.93,18.64l-1.8-1.08L35.92,3.78c-.26-.16-.23-.55,.06-.65C43.75,.36,52.11-.6,60.39,.36c4.16,.48,8.24,1.44,12.15,2.85,.11,.04,.2,.14,.23,.25l6.43,25.31c.08,.31-.26,.56-.53,.4l-17.74-10.54Z"/>
            </g>
            <g>
                <path class="color-c" d="M114.45,82.51h1.45v12.31h7.43l-.23,1.28h-8.65v-13.59Z"/>
                <path class="color-c" d="M139.5,91.71l-1.66,4.39h-1.47l5.12-13.59h1.75l5.34,13.59h-1.57l-1.71-4.39h-5.81Zm5.37-1.28c-1.48-3.83-2.24-5.73-2.53-6.69h-.02c-.32,1.04-1.18,3.4-2.38,6.69h4.93Z"/>
                <path class="color-c" d="M162.5,82.51h5.24c2.75,0,4.16,1.4,4.16,3.37,0,1.62-.99,2.63-2.07,2.95,1.01,.29,2.56,1.22,2.56,3.31,0,2.67-2.06,3.95-4.53,3.95h-5.36v-13.59Zm4.88,5.73c2.14,0,3.02-.81,3.02-2.25,0-1.36-.99-2.23-2.73-2.23h-3.74v4.49h3.45Zm-3.45,6.61h3.77c1.73,0,3.18-.78,3.18-2.68,0-1.64-1.11-2.69-3.59-2.69h-3.36v5.36Z"/>
                <path class="color-c" d="M198.91,89.23c0,3.75-2.16,7.09-6.44,7.09-3.99,0-6.33-3.1-6.33-7.02s2.34-7,6.46-7c3.87,0,6.32,2.97,6.32,6.93Zm-11.24,0c0,3.03,1.68,5.8,4.86,5.8,3.36,0,4.84-2.71,4.84-5.79s-1.62-5.68-4.85-5.68-4.85,2.68-4.85,5.67Z"/>
                <path class="color-c" d="M214.97,89.96v6.14h-1.45v-13.59h5.33c2.73,0,4.27,1.42,4.27,3.61,0,1.72-.97,2.84-2.45,3.2,1.2,.3,2.19,1.14,2.19,3.48v.56c0,.97-.05,2.19,.17,2.74h-1.45c-.24-.59-.2-1.66-.2-2.85v-.35c0-2.1-.65-2.94-3.17-2.94h-3.25Zm0-1.26h3.35c2.24,0,3.27-.83,3.27-2.5,0-1.56-1.04-2.43-3.06-2.43h-3.57v4.93Z"/>
                <path class="color-c" d="M239.81,91.71l-1.66,4.39h-1.47l5.12-13.59h1.75l5.34,13.59h-1.57l-1.71-4.39h-5.81Zm5.37-1.28c-1.48-3.83-2.24-5.73-2.53-6.69h-.02c-.32,1.04-1.18,3.4-2.38,6.69h4.93Z"/>
                <path class="color-c" d="M264.01,83.79h-4.64v-1.28h10.76v1.28h-4.65v12.31h-1.47v-12.31Z"/>
                <path class="color-c" d="M295.35,89.23c0,3.75-2.16,7.09-6.44,7.09-3.99,0-6.33-3.1-6.33-7.02s2.34-7,6.46-7c3.87,0,6.31,2.97,6.31,6.93Zm-11.24,0c0,3.03,1.68,5.8,4.86,5.8,3.36,0,4.84-2.71,4.84-5.79s-1.62-5.68-4.85-5.68-4.85,2.68-4.85,5.67Z"/>
                <path class="color-c" d="M311.41,89.96v6.14h-1.45v-13.59h5.33c2.73,0,4.27,1.42,4.27,3.61,0,1.72-.97,2.84-2.45,3.2,1.2,.3,2.19,1.14,2.19,3.48v.56c0,.97-.05,2.19,.17,2.74h-1.45c-.24-.59-.2-1.66-.2-2.85v-.35c0-2.1-.65-2.94-3.17-2.94h-3.25Zm0-1.26h3.35c2.24,0,3.27-.83,3.27-2.5,0-1.56-1.04-2.43-3.06-2.43h-3.57v4.93Z"/>
                <path class="color-c" d="M335.89,82.51v13.59h-1.45v-13.59h1.45Z"/>
                <path class="color-c" d="M360.01,89.59h-7.14v5.23h7.84l-.2,1.28h-9.08v-13.59h8.93v1.28h-7.5v4.53h7.14v1.28Z"/>
                <path class="color-c" d="M375.55,92.34c.41,1.71,1.59,2.74,3.83,2.74,2.41,0,3.35-1.12,3.35-2.5,0-1.45-.7-2.31-3.72-3-3.22-.73-4.36-1.79-4.36-3.71s1.55-3.58,4.51-3.58c3.37,0,4.6,1.94,4.77,3.57h-1.49c-.28-1.26-1.09-2.34-3.34-2.34-1.86,0-2.93,.81-2.93,2.23,0,1.35,.77,1.94,3.38,2.52,4.05,.92,4.71,2.44,4.71,4.16,0,2.1-1.57,3.85-4.95,3.85s-4.93-1.88-5.24-3.94h1.49Z"/>
            </g>
            <g>
                <path class="color-c" d="M96.78,67.46l-5.03,8.9h-8.25l21.07-37.17h9.89l6,37.17h-7.9l-1.41-8.9h-14.38Zm13.7-6.38c-1.13-7.91-1.84-13.06-2.05-16.13h-.05c-1.56,3.34-4.54,9.01-8.4,16.13h10.5Z"/>
                <path class="color-c" d="M135.47,39.19h14.66c7.64,0,12.5,4.18,12.5,10.78,0,8.64-6.45,12.81-15.85,12.81h-8.72l-2.79,13.59h-7.67l7.88-37.17Zm3.92,17.39h7.09c5.13,0,8.16-1.78,8.15-6.18,0-3.26-1.99-5.02-6.3-5.02h-6.54l-2.39,11.2Z"/>
                <path class="color-c" d="M195.58,60.19h-18.24l-2.05,9.8h20.84l-2.25,6.38h-27.55l7.88-37.17h26.78l-1.38,6.38h-19.15l-1.72,8.24h18.24l-1.4,6.38Z"/>
                <path class="color-c" d="M213.96,61.44l-3.15,14.92h-7.67l7.88-37.17h15.68c7.08,0,11.69,3.61,11.68,9.75,0,5.32-3.28,8.46-7.67,9.77,1.38,.63,4.38,2.55,3.07,8.88-.92,4.19-1.38,7.06-1.12,8.77h-7.54c-.23-1.63-.04-4.1,.97-8.67,.89-4.2,.12-6.25-4.09-6.25h-8.04Zm1.29-6.18h7.51c4.15,0,7.56-1.34,7.56-5.49,0-2.82-1.75-4.4-5.5-4.4h-7.49l-2.08,9.89Z"/>
                <path class="color-c" d="M256.29,45.57h-11.72l1.38-6.38h31.17l-1.38,6.38h-11.71l-6.47,30.8h-7.76l6.5-30.8Z"/>
                <path class="color-c" d="M292.86,39.19l-4.74,22.19c-1.51,7.02,1.69,9.47,6.49,9.47,5.27,0,7.64-2.08,9.16-9.15l4.77-22.52h7.77l-4.93,23.08c-2.28,10.49-6.81,14.74-17.64,14.74-8.65,0-15.89-3.79-13.29-16.06l4.66-21.76h7.76Z"/>
                <path class="color-c" d="M330.58,61.44l-3.15,14.92h-7.67l7.88-37.17h15.68c7.08,0,11.69,3.61,11.68,9.75,0,5.32-3.28,8.46-7.67,9.77,1.38,.63,4.38,2.55,3.07,8.88-.92,4.19-1.38,7.06-1.12,8.77h-7.54c-.23-1.63-.04-4.1,.97-8.67,.89-4.2,.12-6.25-4.09-6.25h-8.04Zm1.29-6.18h7.51c4.15,0,7.56-1.34,7.56-5.49,0-2.82-1.75-4.4-5.5-4.4h-7.49l-2.08,9.89Z"/>
                <path class="color-c" d="M388.65,60.19h-18.24l-2.05,9.8h20.84l-2.25,6.38h-27.55l7.88-37.17h26.78l-1.38,6.38h-19.15l-1.72,8.24h18.24l-1.4,6.38Z"/>
            </g>
            </svg>
        </div>
        </>
    )
}

export default ColorPalette;