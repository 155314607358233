import React, { useEffect, useState } from "react"
import "../svg-css/wheatley_logo.css"
import { useWindowScroll } from "react-use"
import { scale, linearScaler, sinScaler } from "../../../../../scalers/scale"
import { useSize } from "../../../../../hooks/useSize"
import { document } from "browser-monads"

const WheatleyLogo = () => {
    const scrollInfo = useWindowScroll();
    const size = useSize();
    const pageHeight = document.documentElement.offsetHeight;

    const [weatleyLogo_opa, setWeatleyLogo_opa] = useState(0);
    const [weatleyLogo_top, setWeatleyLogo_top] = useState();

    useEffect(() => {
        let percentage = scrollInfo.y/(pageHeight-size.height)*100;
        setWeatleyLogo_top(scale([99, 100], [-size.height*0.2, size.height*0.5], percentage, sinScaler));
        setWeatleyLogo_opa(scale([99, 100], [0, 1], percentage, linearScaler));
    }, [scrollInfo, pageHeight, size]);

    return (
        <>
        <div className="weatleyLogo" style={{
            width: "70%",
            position: "fixed",
            left: "50%",
            top: weatleyLogo_top,
            transform: "translate(-50%, -50%)",
            opacity: weatleyLogo_opa   
        }}>
            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.46 109.35">
            <g>
                <path class="wheatley-b" d="M121.17,90.68h1.45v12.31h7.43l-.23,1.28h-8.65v-13.59Z"/>
                <path class="wheatley-b" d="M146.22,99.88l-1.66,4.39h-1.47l5.12-13.59h1.75l5.34,13.59h-1.57l-1.71-4.39h-5.81Zm5.37-1.28c-1.48-3.83-2.24-5.73-2.53-6.69h-.02c-.32,1.04-1.18,3.4-2.38,6.69h4.93Z"/>
                <path class="wheatley-b" d="M169.22,90.68h5.24c2.75,0,4.16,1.4,4.16,3.37,0,1.62-.99,2.63-2.07,2.95,1.01,.29,2.56,1.22,2.56,3.31,0,2.67-2.06,3.95-4.53,3.95h-5.36v-13.59Zm4.88,5.73c2.14,0,3.02-.81,3.02-2.25,0-1.36-.99-2.23-2.73-2.23h-3.74v4.49h3.45Zm-3.45,6.61h3.77c1.74,0,3.18-.78,3.18-2.68,0-1.64-1.11-2.69-3.59-2.69h-3.36v5.36Z"/>
                <path class="wheatley-b" d="M205.64,97.39c0,3.75-2.16,7.09-6.44,7.09-3.99,0-6.33-3.1-6.33-7.02s2.34-7,6.46-7c3.87,0,6.31,2.97,6.31,6.93Zm-11.24,0c0,3.03,1.68,5.8,4.86,5.8,3.36,0,4.84-2.71,4.84-5.79s-1.62-5.68-4.85-5.68-4.86,2.68-4.86,5.67Z"/>
                <path class="wheatley-b" d="M221.7,98.13v6.14h-1.45v-13.59h5.33c2.73,0,4.27,1.42,4.27,3.61,0,1.72-.97,2.84-2.45,3.2,1.2,.3,2.19,1.14,2.19,3.48v.56c0,.97-.05,2.19,.17,2.74h-1.45c-.24-.59-.2-1.66-.2-2.85v-.35c0-2.1-.65-2.94-3.17-2.94h-3.25Zm0-1.26h3.35c2.24,0,3.27-.83,3.27-2.5,0-1.56-1.04-2.43-3.06-2.43h-3.57v4.93Z"/>
                <path class="wheatley-b" d="M246.54,99.88l-1.66,4.39h-1.47l5.12-13.59h1.75l5.34,13.59h-1.57l-1.71-4.39h-5.81Zm5.37-1.28c-1.48-3.83-2.24-5.73-2.53-6.69h-.02c-.32,1.04-1.19,3.4-2.38,6.69h4.93Z"/>
                <path class="wheatley-b" d="M270.73,91.96h-4.64v-1.28h10.76v1.28h-4.65v12.31h-1.47v-12.31Z"/>
                <path class="wheatley-b" d="M302.08,97.39c0,3.75-2.16,7.09-6.44,7.09-3.99,0-6.33-3.1-6.33-7.02s2.34-7,6.46-7c3.87,0,6.32,2.97,6.32,6.93Zm-11.24,0c0,3.03,1.68,5.8,4.86,5.8,3.37,0,4.84-2.71,4.84-5.79s-1.63-5.68-4.85-5.68-4.86,2.68-4.86,5.67Z"/>
                <path class="wheatley-b" d="M318.13,98.13v6.14h-1.45v-13.59h5.33c2.73,0,4.27,1.42,4.27,3.61,0,1.72-.97,2.84-2.45,3.2,1.2,.3,2.19,1.14,2.19,3.48v.56c0,.97-.05,2.19,.17,2.74h-1.45c-.24-.59-.2-1.66-.2-2.85v-.35c0-2.1-.65-2.94-3.17-2.94h-3.25Zm0-1.26h3.35c2.24,0,3.27-.83,3.27-2.5,0-1.56-1.04-2.43-3.06-2.43h-3.57v4.93Z"/>
                <path class="wheatley-b" d="M342.62,90.68v13.59h-1.45v-13.59h1.45Z"/>
                <path class="wheatley-b" d="M366.74,97.76h-7.14v5.23h7.84l-.2,1.28h-9.08v-13.59h8.93v1.28h-7.5v4.53h7.14v1.28Z"/>
                <path class="wheatley-b" d="M382.28,100.51c.42,1.71,1.59,2.74,3.83,2.74,2.41,0,3.35-1.12,3.35-2.5,0-1.45-.7-2.31-3.72-3-3.22-.73-4.36-1.79-4.36-3.71s1.55-3.58,4.51-3.58c3.37,0,4.6,1.94,4.77,3.57h-1.49c-.28-1.26-1.09-2.34-3.34-2.34-1.86,0-2.93,.81-2.93,2.23,0,1.35,.77,1.94,3.38,2.52,4.05,.92,4.71,2.44,4.71,4.16,0,2.1-1.57,3.85-4.95,3.85s-4.93-1.88-5.24-3.94h1.49Z"/>
            </g>
            <g>
                <path class="wheatley-b" d="M117.87,74.34l-4.83-36.9,7.68-.64c.96,10.29,2.6,24.13,2.5,29.3h.05c1.63-5.97,7.82-21.51,11.2-30.44l7.09-.59c1.05,7.6,3.23,23.11,3.55,28.89h.07c1.52-5.93,5.9-17.65,10.18-30.03l7.79-.65-15.13,38.56-8.21,.68c-1.08-8.35-2.96-22.07-3.3-27.13h-.05c-1.58,6.17-7.21,20.13-10.42,28.27l-8.17,.68Z"/>
                <path class="wheatley-b" d="M171.11,32.62l7.73-.64-1.84,14.58,15.78-1.31,1.85-14.58,7.75-.64-4.78,37.7-7.75,.64,2.11-16.65-15.78,1.31-2.11,16.65-7.73,.64,4.78-37.7Z"/>
                <path class="wheatley-b" d="M237.21,48.21l-18.18,1.51-1.23,9.93,20.77-1.72-1.72,6.54-27.45,2.28,4.78-37.7,26.68-2.21-.84,6.47-19.08,1.58-1.03,8.36,18.18-1.51-.87,6.47Z"/>
                <path class="wheatley-b" d="M256.47,53.91l-4.28,9.29-8.22,.68,17.93-38.79,9.86-.82,9.05,36.55-7.88,.65-2.14-8.76-14.33,1.19Zm13.13-7.49c-1.78-7.79-2.91-12.86-3.38-15.9h-.05c-1.28,3.46-3.78,9.36-7.04,16.77l10.47-.87Z"/>
                <path class="wheatley-b" d="M295.07,28.74l-11.68,.97,.85-6.47,31.06-2.58-.85,6.47-11.67,.97-3.9,31.23-7.73,.64,3.93-31.23Z"/>
                <path class="wheatley-b" d="M323.49,19.98l7.73-.64-3.96,31.23,18.93-1.57-1.74,6.54-25.74,2.14,4.78-37.7Z"/>
                <path class="wheatley-b" d="M380.55,36.32l-18.18,1.51-1.23,9.93,20.77-1.72-1.72,6.54-27.46,2.28,4.78-37.7,26.69-2.21-.84,6.47-19.08,1.58-1.03,8.36,18.18-1.51-.87,6.47Z"/>
                <path class="wheatley-b" d="M399.05,51.02l1.7-13.67c.06-.46-.01-.76-.22-1.17l-10.47-21.72,8.24-.68c2.57,5.9,5.85,13.3,7.12,16.86,2.06-3.74,7.88-12.45,11.72-18.42l8.33-.69-16.61,24.68c-.23,.32-.36,.64-.43,1.28l-1.61,12.88-7.77,.64Z"/>
            </g>
            <g>
                <path class="wheatley-b" d="M5.82,31.38c-.37,.09-.68-.29-.52-.63,3.53-7.39,8.74-13.91,15.22-19.06,3.27-2.59,6.82-4.78,10.55-6.54,.14-.06,.3-.06,.43,.02l22.26,13.22c.34,.2,.27,.72-.12,.82l-21.87,5.56L5.82,31.38Z"/>
                <path class="wheatley-b" d="M3.88,72.21c-.19,.33-.69,.28-.81-.08C.34,64.4-.59,56.11,.36,47.89c.48-4.13,1.43-8.19,2.82-12.07,.05-.14,.17-.25,.32-.29l25.09-6.39c.39-.1,.7,.32,.5,.66l-10.62,17.87-.9,1.5L3.88,72.21Z"/>
                <path class="wheatley-b" d="M31.4,102.47c.09,.37-.29,.68-.63,.52-7.41-3.54-13.94-8.76-19.08-15.25-2.58-3.25-4.77-6.78-6.53-10.52-.06-.14-.06-.3,.02-.43l13.22-22.26c.2-.34,.72-.27,.82,.12l12.18,47.82Z"/>
                <path class="wheatley-b" d="M47.89,107.92c-4.13-.48-8.17-1.43-12.05-2.82-.14-.05-.25-.17-.29-.32l-6.39-25.09c-.1-.39,.32-.7,.66-.49l17.89,10.63,1.49,.89,23.01,13.68c.33,.19,.28,.69-.08,.81-7.72,2.72-16,3.65-24.22,2.7h-.02Z"/>
                <path class="wheatley-b" d="M54.56,89.89c-.34-.2-.27-.72,.12-.82l20.12-5.12h0l28.57-7.27c-3.54,7.74-8.9,14.57-15.64,19.91-3.25,2.57-6.78,4.76-10.53,6.53-.14,.06-.3,.06-.43-.02l-22.23-13.21Z"/>
                <path class="wheatley-b" d="M76.94,5.84c-.09-.37,.29-.68,.63-.52,7.37,3.54,13.89,8.73,19.01,15.2,2.63,3.32,4.86,6.93,6.64,10.75l-13.32,22.45c-.2,.34-.72,.27-.82-.12L76.94,5.84Z"/>
                <path class="wheatley-b" d="M60.93,18.64l-1.8-1.08L36.08,3.88c-.33-.19-.28-.69,.08-.81C43.88,.34,52.17-.59,60.39,.36c4.15,.48,8.21,1.43,12.11,2.83,.14,.05,.25,.17,.29,.32l6.37,25.06c.1,.39-.32,.7-.66,.49l-17.56-10.43Z"/>
            </g>
            </svg>
        </div>
        </>
    )
}

export default WheatleyLogo;