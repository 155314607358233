import React, { useEffect, useState } from "react"
import "../svg-css/still_character_logo.css"
import { useWindowScroll } from "react-use"
import { scale, linearScaler } from "../../../../../scalers/scale"
import { useSize } from "../../../../../hooks/useSize"
import { document } from "browser-monads"

const StillCharLogo = () => {
    const scrollInfo = useWindowScroll();
    const size = useSize();
    const pageHeight = document.documentElement.offsetHeight;

    const [stillCharLogo_opa, setStillCharLogo_opa] = useState(0);

    useEffect(() => {
        let percentage = scrollInfo.y/(pageHeight-size.height)*100;
        setStillCharLogo_opa(scale([50, 52, 54, 56], [0, 1, 1, 0], percentage, linearScaler));
    }, [scrollInfo, pageHeight, size]);

    return (
        <>
        <div className="stillCharLogo" style={{
            width: "70%",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            opacity: stillCharLogo_opa   
        }}>
            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 388.07 84.25">
                <g>
                    <path class="still-char-b" d="M42.14,38.18l-5.26,9.31h-8.63L50.29,8.6h10.35l6.28,38.89h-8.27l-1.47-9.31h-15.04Zm14.33-6.67c-1.18-8.28-1.92-13.66-2.14-16.87h-.06c-1.63,3.49-4.75,9.42-8.79,16.87h10.99Z"/>
                    <path class="still-char-b" d="M82.62,8.6h15.33c8,0,13.07,4.37,13.07,11.27,0,9.04-6.75,13.4-16.58,13.4h-9.12l-2.92,14.21h-8.02l8.24-38.89Zm4.1,18.19h7.41c5.36,0,8.53-1.87,8.53-6.47,0-3.41-2.08-5.25-6.59-5.25h-6.85l-2.51,11.72Z"/>
                    <path class="still-char-b" d="M145.49,30.57h-19.08l-2.15,10.25h21.8l-2.36,6.67h-28.82l8.24-38.89h28.01l-1.44,6.67h-20.03l-1.8,8.62h19.08l-1.47,6.67Z"/>
                    <path class="still-char-b" d="M164.72,31.88l-3.3,15.61h-8.03l8.24-38.89h16.4c7.4,0,12.23,3.78,12.22,10.19,0,5.57-3.43,8.85-8.03,10.22,1.45,.66,4.58,2.67,3.21,9.29-.96,4.38-1.45,7.39-1.17,9.18h-7.89c-.24-1.71-.04-4.29,1.01-9.07,.93-4.4,.13-6.54-4.27-6.54h-8.41Zm1.35-6.47h7.86c4.34,0,7.91-1.4,7.91-5.74,0-2.95-1.83-4.61-5.76-4.61h-7.83l-2.17,10.34Z"/>
                    <path class="still-char-b" d="M209,15.28h-12.26l1.44-6.67h32.6l-1.44,6.67h-12.25l-6.77,32.21h-8.12l6.8-32.21Z"/>
                    <path class="still-char-b" d="M247.25,8.6l-4.96,23.22c-1.58,7.34,1.77,9.91,6.79,9.91,5.51,0,8-2.17,9.58-9.57l4.99-23.55h8.12l-5.16,24.14c-2.39,10.98-7.13,15.42-18.45,15.42-9.05,0-16.63-3.96-13.91-16.8l4.87-22.76h8.12Z"/>
                    <path class="still-char-b" d="M286.71,31.88l-3.3,15.61h-8.03l8.24-38.89h16.4c7.4,0,12.23,3.78,12.22,10.19,0,5.57-3.43,8.85-8.03,10.22,1.45,.66,4.58,2.67,3.21,9.29-.96,4.38-1.45,7.39-1.17,9.18h-7.89c-.24-1.71-.04-4.29,1.01-9.07,.93-4.4,.13-6.54-4.27-6.54h-8.41Zm1.35-6.47h7.86c4.34,0,7.91-1.4,7.91-5.74,0-2.95-1.83-4.61-5.76-4.61h-7.83l-2.17,10.34Z"/>
                    <path class="still-char-b" d="M347.45,30.57h-19.08l-2.15,10.25h21.8l-2.36,6.67h-28.82l8.24-38.89h28.01l-1.44,6.67h-20.03l-1.8,8.62h19.08l-1.47,6.67Z"/>
                </g>
                <path class="still-char-b" d="M0,64H2.08v17.71H12.77l-.33,1.84H0v-19.55Z"/>
                <path class="still-char-b" d="M36.03,77.23l-2.39,6.31h-2.12l7.37-19.55h2.52l7.68,19.55h-2.25l-2.46-6.31h-8.35Zm7.73-1.84c-2.13-5.51-3.22-8.24-3.64-9.62h-.03c-.46,1.5-1.7,4.9-3.43,9.62h7.09Z"/>
                <path class="still-char-b" d="M69.12,64h7.54c3.96,0,5.98,2.01,5.98,4.85,0,2.34-1.42,3.78-2.98,4.24,1.45,.42,3.68,1.76,3.68,4.77,0,3.84-2.96,5.69-6.52,5.69h-7.71v-19.55Zm7.02,8.25c3.08,0,4.35-1.16,4.35-3.24,0-1.95-1.43-3.21-3.93-3.21h-5.38v6.45h4.97Zm-4.97,9.51h5.43c2.5,0,4.58-1.12,4.58-3.85,0-2.36-1.6-3.86-5.17-3.86h-4.83v7.71Z"/>
                <path class="still-char-b" d="M144.59,74.72v8.83h-2.09v-19.55h7.66c3.92,0,6.14,2.04,6.14,5.19,0,2.47-1.39,4.08-3.52,4.61,1.73,.43,3.15,1.64,3.15,5.01v.8c0,1.39-.07,3.15,.25,3.94h-2.08c-.34-.84-.29-2.39-.29-4.1v-.5c0-3.02-.94-4.23-4.56-4.23h-4.67Zm0-1.82h4.83c3.22,0,4.71-1.19,4.71-3.6,0-2.25-1.49-3.49-4.4-3.49h-5.13v7.09Z"/>
                <path class="still-char-b" d="M180.32,77.23l-2.39,6.31h-2.12l7.37-19.55h2.52l7.68,19.55h-2.25l-2.46-6.31h-8.35Zm7.73-1.84c-2.13-5.51-3.22-8.24-3.64-9.62h-.03c-.46,1.5-1.7,4.9-3.43,9.62h7.09Z"/>
                <path class="still-char-b" d="M215.11,65.84h-6.68v-1.84h15.48v1.84h-6.69v17.71h-2.11v-17.71Z"/>
                <path class="still-char-b" d="M260.2,73.66c0,5.4-3.1,10.19-9.26,10.19-5.74,0-9.11-4.46-9.11-10.1s3.36-10.07,9.29-10.07c5.57,0,9.08,4.28,9.08,9.97Zm-16.16,.01c0,4.36,2.41,8.35,6.99,8.35,4.84,0,6.96-3.89,6.96-8.32s-2.34-8.18-6.97-8.18-6.98,3.85-6.98,8.15Z"/>
                <path class="still-char-b" d="M283.29,74.72v8.83h-2.09v-19.55h7.66c3.92,0,6.14,2.04,6.14,5.19,0,2.47-1.39,4.08-3.52,4.61,1.73,.43,3.15,1.64,3.15,5.01v.8c0,1.39-.07,3.15,.25,3.94h-2.08c-.34-.84-.29-2.39-.29-4.1v-.5c0-3.02-.94-4.23-4.55-4.23h-4.67Zm0-1.82h4.83c3.22,0,4.71-1.19,4.71-3.6,0-2.25-1.49-3.49-4.4-3.49h-5.13v7.09Z"/>
                <path class="still-char-b" d="M318.5,64v19.55h-2.09v-19.55h2.09Z"/>
                <path class="still-char-b" d="M353.2,74.18h-10.27v7.53h11.28l-.29,1.84h-13.06v-19.55h12.85v1.84h-10.78v6.51h10.27v1.84Z"/>
                <path class="still-char-b" d="M375.55,78.14c.6,2.47,2.28,3.94,5.51,3.94,3.46,0,4.82-1.62,4.82-3.6,0-2.08-1-3.33-5.35-4.31-4.63-1.05-6.27-2.57-6.27-5.33s2.24-5.15,6.49-5.15c4.84,0,6.61,2.79,6.86,5.14h-2.14c-.4-1.82-1.56-3.37-4.81-3.37-2.67,0-4.22,1.16-4.22,3.2,0,1.94,1.11,2.79,4.86,3.63,5.83,1.32,6.77,3.51,6.77,5.98,0,3.02-2.26,5.53-7.13,5.53s-7.1-2.7-7.54-5.67h2.14Z"/>
                <g>
                    <path class="still-char-b" d="M117.4,64.18c1.4,.69,2.64,1.67,3.64,2.87,.34,.41,.64,.84,.92,1.3l-2.46,3.92-2.09-8.09Z"/>
                    <path class="still-char-b" d="M113.8,80.51l8.38-2c-.72,1.32-1.72,2.49-2.95,3.43-.44,.33-.9,.63-1.39,.9l-4.04-2.34Z"/>
                    <path class="still-char-b" d="M112.35,84.25c-.43,0-.87-.02-1.3-.07-.55-.06-1.09-.16-1.62-.3l-1.13-4.39,7.37,4.26c-1.07,.32-2.2,.49-3.32,.49Z"/>
                    <path class="still-char-b" d="M107.27,83.08c-1.4-.69-2.64-1.67-3.64-2.87-.34-.41-.64-.84-.92-1.3l2.46-3.92,2.09,8.09Z"/>
                    <path class="still-char-b" d="M101.78,76.87c-.48-1.43-.64-2.95-.45-4.46,.06-.53,.17-1.05,.31-1.56l4.6-1.09-4.46,7.11Z"/>
                    <path class="still-char-b" d="M102.5,68.72c.71-1.31,1.72-2.48,2.94-3.41,.44-.33,.9-.63,1.39-.9l4.03,2.33-8.37,1.98Z"/>
                    <path class="still-char-b" d="M109,63.5c1.07-.32,2.2-.49,3.32-.49,.43,0,.87,.02,1.3,.07,.55,.06,1.09,.16,1.62,.3l1.13,4.39-7.37-4.26Z"/>
                    <path class="still-char-b" d="M122.87,70.33c.48,1.44,.64,2.96,.46,4.48-.06,.52-.17,1.04-.31,1.55l-4.62,1.1,4.47-7.13Z"/>
                </g>
            </svg>
        </div>
        </>
    )
}

export default StillCharLogo;