import React, { useEffect, useState } from "react"
import "../svg-css/still_vertical_logo.css"
import { useWindowScroll } from "react-use"
import { scale, linearScaler } from "../../../../../scalers/scale"
import { useSize } from "../../../../../hooks/useSize"
import { document } from "browser-monads"

const StillVerticalLogo = () => {
    const scrollInfo = useWindowScroll();
    const size = useSize();
    const pageHeight = document.documentElement.offsetHeight;

    const [stillVerticalLogo_opa, setStillVerticalLogo_opa] = useState(0);

    useEffect(() => {
        let percentage = scrollInfo.y/(pageHeight-size.height)*100;
        setStillVerticalLogo_opa(scale([44, 46, 48, 50], [0, 1, 1, 0], percentage, linearScaler));
    }, [scrollInfo, pageHeight, size]);

    return (
        <>
        <div className="stillVerticalLogo" style={{
            width: "50%",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            opacity: stillVerticalLogo_opa   
        }}>
            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.19 315.44">
                <g>
                    <g>
                        <path class="still-vertical-c" d="M17.17,274.04l-6.25,11.06H.67l26.18-46.18h12.29l7.46,46.18h-9.82l-1.75-11.06H17.17Zm17.02-7.92c-1.4-9.83-2.28-16.22-2.55-20.04h-.06c-1.94,4.15-5.64,11.19-10.44,20.04h13.05Z"/>
                        <path class="still-vertical-c" d="M65.25,238.92h18.21c9.5,0,15.53,5.19,15.53,13.39,0,10.74-8.01,15.92-19.69,15.92h-10.84l-3.47,16.88h-9.53l9.79-46.18Zm4.87,21.6h8.8c6.37,0,10.14-2.22,10.13-7.68,0-4.05-2.47-6.24-7.83-6.24h-8.13l-2.98,13.92Z"/>
                        <path class="still-vertical-c" d="M139.93,265.01h-22.66l-2.55,12.17h25.89l-2.8,7.92h-34.22l9.79-46.18h33.27l-1.71,7.92h-23.79l-2.13,10.24h22.66l-1.74,7.92Z"/>
                        <path class="still-vertical-c" d="M162.77,266.56l-3.92,18.54h-9.53l9.79-46.18h19.48c8.79,0,14.52,4.49,14.52,12.11,0,6.61-4.08,10.51-9.53,12.14,1.72,.78,5.45,3.16,3.81,11.04-1.14,5.2-1.72,8.77-1.39,10.9h-9.37c-.28-2.03-.05-5.1,1.21-10.77,1.1-5.22,.15-7.77-5.08-7.77h-9.99Zm1.6-7.68h9.33c5.16,0,9.39-1.67,9.39-6.82,0-3.5-2.17-5.47-6.84-5.47h-9.3l-2.58,12.29Z"/>
                        <path class="still-vertical-c" d="M215.36,246.84h-14.56l1.71-7.92h38.72l-1.71,7.92h-14.55l-8.04,38.26h-9.64l8.08-38.26Z"/>
                        <path class="still-vertical-c" d="M260.8,238.92l-5.89,27.57c-1.87,8.72,2.1,11.77,8.06,11.77,6.54,0,9.5-2.58,11.38-11.36l5.93-27.97h9.65l-6.12,28.67c-2.83,13.04-8.46,18.32-21.92,18.32-10.75,0-19.75-4.71-16.52-19.96l5.79-27.03h9.65Z"/>
                        <path class="still-vertical-c" d="M307.67,266.56l-3.92,18.54h-9.53l9.79-46.18h19.48c8.79,0,14.52,4.49,14.52,12.11,0,6.61-4.08,10.51-9.53,12.14,1.72,.78,5.44,3.16,3.81,11.04-1.14,5.2-1.72,8.77-1.39,10.9h-9.37c-.28-2.03-.05-5.1,1.21-10.77,1.1-5.22,.15-7.77-5.07-7.77h-9.99Zm1.6-7.68h9.33c5.16,0,9.39-1.67,9.39-6.82,0-3.5-2.17-5.47-6.84-5.47h-9.3l-2.58,12.29Z"/>
                        <path class="still-vertical-c" d="M379.81,265.01h-22.66l-2.55,12.17h25.89l-2.8,7.92h-34.23l9.79-46.18h33.26l-1.71,7.92h-23.79l-2.13,10.24h22.66l-1.74,7.92Z"/>
                    </g>
                    <g>
                        <path class="still-vertical-c" d="M0,295.63H2.08v17.67H12.74l-.33,1.84H0v-19.5Z"/>
                        <path class="still-vertical-c" d="M35.95,308.83l-2.38,6.3h-2.11l7.35-19.5h2.51l7.66,19.5h-2.25l-2.45-6.3h-8.33Zm7.71-1.84c-2.12-5.5-3.22-8.22-3.63-9.6h-.03c-.46,1.5-1.7,4.88-3.42,9.6h7.08Z"/>
                        <path class="still-vertical-c" d="M68.96,295.63h7.53c3.95,0,5.97,2.01,5.97,4.84,0,2.33-1.42,3.77-2.97,4.23,1.45,.42,3.67,1.75,3.67,4.75,0,3.83-2.96,5.67-6.5,5.67h-7.69v-19.5Zm7,8.23c3.08,0,4.34-1.15,4.34-3.24,0-1.95-1.43-3.2-3.92-3.2h-5.37v6.44h4.95Zm-4.95,9.48h5.41c2.49,0,4.57-1.12,4.57-3.84,0-2.35-1.59-3.85-5.16-3.85h-4.82v7.69Z"/>
                        <path class="still-vertical-c" d="M121.22,305.27c0,5.38-3.09,10.17-9.24,10.17-5.73,0-9.09-4.45-9.09-10.07s3.35-10.04,9.27-10.04c5.55,0,9.06,4.27,9.06,9.95Zm-16.13,.01c0,4.35,2.41,8.33,6.98,8.33,4.83,0,6.95-3.88,6.95-8.3s-2.33-8.16-6.95-8.16-6.97,3.84-6.97,8.13Z"/>
                        <path class="still-vertical-c" d="M144.26,306.32v8.81h-2.08v-19.5h7.64c3.91,0,6.12,2.04,6.12,5.18,0,2.47-1.39,4.07-3.51,4.6,1.72,.43,3.15,1.64,3.15,5v.8c0,1.39-.07,3.14,.25,3.93h-2.07c-.35-.84-.29-2.39-.29-4.09v-.5c0-3.02-.94-4.22-4.54-4.22h-4.66Zm0-1.81h4.81c3.21,0,4.7-1.19,4.7-3.59,0-2.25-1.49-3.48-4.39-3.48h-5.12v7.07Z"/>
                        <path class="still-vertical-c" d="M179.91,308.83l-2.38,6.3h-2.11l7.35-19.5h2.51l7.66,19.5h-2.25l-2.45-6.3h-8.33Zm7.71-1.84c-2.12-5.5-3.21-8.22-3.63-9.6h-.03c-.46,1.5-1.7,4.88-3.42,9.6h7.08Z"/>
                        <path class="still-vertical-c" d="M214.63,297.46h-6.66v-1.84h15.44v1.84h-6.67v17.67h-2.11v-17.67Z"/>
                        <path class="still-vertical-c" d="M259.61,305.27c0,5.38-3.09,10.17-9.24,10.17-5.73,0-9.09-4.45-9.09-10.07s3.35-10.04,9.27-10.04c5.55,0,9.06,4.27,9.06,9.95Zm-16.13,.01c0,4.35,2.4,8.33,6.98,8.33,4.83,0,6.95-3.88,6.95-8.3s-2.33-8.16-6.96-8.16-6.97,3.84-6.97,8.13Z"/>
                        <path class="still-vertical-c" d="M282.66,306.32v8.81h-2.08v-19.5h7.64c3.91,0,6.12,2.04,6.12,5.18,0,2.47-1.39,4.07-3.51,4.6,1.72,.43,3.15,1.64,3.15,5v.8c0,1.39-.07,3.14,.25,3.93h-2.07c-.34-.84-.29-2.39-.29-4.09v-.5c0-3.02-.94-4.22-4.54-4.22h-4.66Zm0-1.81h4.81c3.21,0,4.7-1.19,4.7-3.59,0-2.25-1.49-3.48-4.39-3.48h-5.12v7.07Z"/>
                        <path class="still-vertical-c" d="M317.79,295.63v19.5h-2.08v-19.5h2.08Z"/>
                        <path class="still-vertical-c" d="M352.4,305.79h-10.25v7.51h11.25l-.29,1.83h-13.02v-19.5h12.82v1.83h-10.76v6.5h10.25v1.83Z"/>
                        <path class="still-vertical-c" d="M374.71,309.74c.59,2.46,2.28,3.93,5.49,3.93,3.45,0,4.81-1.61,4.81-3.59,0-2.08-1-3.32-5.33-4.3-4.62-1.05-6.26-2.56-6.26-5.32s2.23-5.14,6.48-5.14c4.83,0,6.6,2.78,6.85,5.13h-2.13c-.4-1.81-1.56-3.36-4.8-3.36-2.67,0-4.21,1.16-4.21,3.2,0,1.93,1.11,2.78,4.84,3.62,5.81,1.32,6.75,3.5,6.75,5.97,0,3.01-2.25,5.52-7.11,5.52s-7.08-2.69-7.52-5.66h2.14Z"/>
                    </g>
                </g>
                <g>
                    <path class="still-vertical-c" d="M94.12,64.46c-.61,.15-1.12-.47-.86-1.03,7.25-15.29,17.98-28.81,31.39-39.44,6.74-5.32,14.04-9.85,21.73-13.46,.22-.11,.49-.09,.7,.03l46.1,27.38c.57,.34,.44,1.19-.2,1.35l-45.26,11.52-53.61,13.65Z"/>
                    <path class="still-vertical-c" d="M90.33,148.43c-.32,.54-1.13,.46-1.34-.13-5.69-15.95-7.66-33.1-5.68-50.08,.99-8.51,2.95-16.86,5.82-24.84,.08-.23,.28-.41,.52-.47l51.96-13.24c.64-.16,1.15,.53,.82,1.09l-22,37.01-1.84,3.08-28.26,47.57Z"/>
                    <path class="still-vertical-c" d="M147.05,210.53c.15,.61-.47,1.12-1.03,.86-15.33-7.27-28.87-18.02-39.48-31.45-5.31-6.69-9.82-13.97-13.43-21.67-.1-.22-.09-.49,.03-.7l27.39-46.1c.34-.57,1.19-.44,1.35,.2l25.17,98.87Z"/>
                    <path class="still-vertical-c" d="M180.78,221.33c-8.49-.99-16.83-2.94-24.82-5.81-.23-.08-.41-.28-.47-.52l-13.23-51.97c-.16-.64,.53-1.15,1.09-.82l37.05,22.03,3.05,1.82,47.53,28.25c.54,.32,.46,1.13-.13,1.34-15.93,5.68-33.06,7.64-50.04,5.68h-.04Z"/>
                    <path class="still-vertical-c" d="M194.08,184.14c-.57-.34-.44-1.19,.2-1.35l41.69-10.61h.02l57.08-14.53c.61-.15,1.13,.47,.86,1.03-7.26,15.28-18,28.79-31.41,39.42-6.68,5.29-13.97,9.8-21.69,13.44-.22,.11-.49,.09-.7-.03l-46.04-27.37Z"/>
                    <path class="still-vertical-c" d="M240.25,11.59c-.15-.61,.47-1.12,1.04-.86,15.27,7.26,28.75,17.98,39.34,31.35,5.3,6.7,9.82,13.98,13.45,21.68,.11,.22,.09,.49-.03,.7l-27.34,46.06c-.34,.57-1.19,.44-1.35-.2L240.25,11.59Z"/>
                    <path class="still-vertical-c" d="M266.45,124.83l2.16-3.62,28.25-47.61c.32-.54,1.13-.46,1.34,.12,5.7,15.96,7.66,33.12,5.69,50.12-.99,8.53-2.95,16.89-5.83,24.89-.08,.23-.28,.41-.52,.47l-51.93,13.21c-.64,.16-1.15-.53-.82-1.09l21.66-36.49Z"/>
                    <path class="still-vertical-c" d="M207.52,38.23l-3.7-2.21L156.22,7.75c-.54-.32-.46-1.13,.13-1.34C172.29,.73,189.43-1.23,206.41,.75c8.54,.99,16.9,2.96,24.93,5.85,.23,.08,.41,.28,.47,.52l13.19,51.91c.16,.64-.53,1.15-1.09,.82l-36.39-21.61Z"/>
                </g>
            </svg>
        </div>
        </>
    )
}

export default StillVerticalLogo;